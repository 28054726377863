<template>
  <travio-center-container grid-width="full" :pageTitle="'Voucher Codes for Voucher Rule ' + voucherRuleId">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="flex flex-wrap justify-start">
          <vs-button @click="addVoucherCode" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
          <vs-button @click="backToVoucherRule" type="border" class="mr-4 sm:mb-0 mb-2">Back to Voucher Rule</vs-button>
        </div>
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="voucherCodes"
          :gridOptions="gridOptions"
          :autoGroupColumnDef="autoGroupColumnDef"
          rowSelection="single"
          :pagination="true"
          :context="context"
          :suppressPaginationPanel="false"
        >
        </ag-grid-vue>

      </vx-card>
    </div>
  </travio-center-container>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import { format } from 'date-fns'
import CellRendererVoucherCodesActions from './cell-renderers/CellRendererVoucherCodesActions.vue'
import CellRendererVoucherCodesIsPctCheckbox from './cell-renderers/CellRendererVoucherCodesIsPctCheckbox.vue'

export default {
  components: {
    AgGridVue,
    CellRendererVoucherCodesActions,
    CellRendererVoucherCodesIsPctCheckbox
  },
  props: {
    applicationId: { required: true },
    voucherRuleId: { required: true }
  },
  data () {
    return {
      voucherCodes: [],
      categoryList: [],
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererVoucherCodesActions,
        CellRendererVoucherCodesIsPctCheckbox
      },
      context: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    // this.voucherCodes = [
    //   {id: 1, code: 'VOUCHER100', name: 'Voucher Code 1', value: 100, expiryDate: '2021-12-21', maxUses: 1, uses: 2 },
    //   {id: 2, code: 'VOUCHER200', name: 'Voucher Code 2', value: 100, expiryDate: '2021-12-21', maxUses: 1, uses: 2 },
    //   {id: 3, code: 'VOUCHER300', name: 'Voucher Code 3', value: 100, expiryDate: '2021-12-21', maxUses: 1, uses: 2 },
    //   {id: 4, code: 'VOUCHER400', name: 'Voucher Code 4', value: 100, expiryDate: '2021-12-21', maxUses: 1, uses: 2 }
    // ]
    
    this.$vs.loading()
      this.$http.get(`/api/vouchercodes/apps/${this.applicationId}/voucherRule/${this.voucherRuleId}`)
      .then(response => {
          this.voucherCodes = response.data
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close())  
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Actions', width: 150, suppressSizeToFit: true, sortable: false, cellRendererFramework: Vue.extend(CellRendererVoucherCodesActions) },
      { headerName: 'Id', field: 'id', width: 100,  suppressSizeToFit: true },
      { field: 'applicationId', hide: true },
      { field: 'voucherRuleId', hide: true },
      { headerName: 'Code', field: 'code', width: 180,  suppressSizeToFit: true },
      { headerName: 'Name', field: 'name', width: 300,  suppressSizeToFit: false },
      { headerName: 'Value', field: 'value', width: 120,  suppressSizeToFit: true },
      { headerName: 'Is Percent', width: 150, suppressSizeToFit: true, sortable: false, cellRendererFramework: Vue.extend(CellRendererVoucherCodesIsPctCheckbox) },
      {
        headerName: 'Expiry Date',
        field: 'expiryDate',
        sortable: true,
        suppressSizeToFit: true,
        width: 200,
        valueFormatter: (param) => (param.value && format(new Date(param.value) , this.activeUserInfo.dateFormat)) || ''
      },
      { headerName: 'Max Uses', field: 'maxUses', width: 120,  suppressSizeToFit: true, valueFormatter: (param) => param.value > 0 ? param.valueFormatter : 'Unlimited' },
      { headerName: 'Uses', field: 'uses', width: 120,  suppressSizeToFit: false },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    addVoucherCode () {
      this.$router.push({ name: 'application-vouchercodes-add', 
        params: { 
          applicationId: this.applicationId,
          voucherRuleId: this.voucherRuleId,
        }
      })
    },
    deleteVoucherCode (voucherCodeId) {
      this.$vs.loading()
      this.$http.delete(`/api/vouchercodes/apps/${this.applicationId}/${voucherCodeId}`)
      .then(response => {
          this.voucherCodes = this.$_.filter(this.voucherCodes, x => x.id !== voucherCodeId)
          // Reassign voucherCodes to trigger change detection
          this.voucherCodes = JSON.parse(JSON.stringify(this.voucherCodes))
          this.$_notifySuccess('Deposit option successfully deleted');
      })
      .catch(error => console.error(error))
      .finally(() => this.$vs.loading.close())  
    },
    backToVoucherRule () {
      this.$router.push({ name: 'application-voucherrules-edit', 
        params: { 
          voucherRuleId: this.voucherRuleId
        }
      })
    },
  }
}
</script>

<style>

</style>